.adminPids {
    display: flex;
  
    .navbar {
      background-color:rgb(247, 245, 242);
      width: 25%;
      min-height: 80vh;
    }
    .content {
      width: 75%;
      padding: 1rem;
    }
  }