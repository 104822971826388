.landheader{
  display: flex;
  max-width: 1280px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  
  align-content: center ;
  padding: 10px;
  background-color: ivory;
  
  .stats{
    background-color: lime;
  }

  h1 {
    color: darkblue;
  }
  h2 {
    color: darkred;

  }

  .logo {
    
    background-color: ivory;
    max-width: 350px;
    min-width: 250px;
  }
  .hero {
    max-width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

  }
  .getstarted {
    display: flex;
    background-color: red;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    box-shadow: 5px 10px;
    p {
      color: darkblue;
    }
    
  }
  .robot {
    // background-color: rgb(232, 18, 218);
    width: 50%;
    
    img {
      width: 350px;
    }
  }
}