.landing {
  display: flex;
  width: 100%;
  height: 100%;
  align-content: center;
  background-color: red;
  .hero{
    align-content: center;
    align-self: center;
    background-color: blue;
  }
  .stats{
    background-color: lime;
  }
}