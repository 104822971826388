.footer {
    background-color: var(--dark-blue);
    color: #fff;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8rem;
    p {
      color: #fff;
    }
  }